.search-slider{
    display: flex;
    
    
     
 }
 .search-slider-info{
     display: flex;
     flex-direction: column;
     gap: 20px;
     flex: 9;
     margin: 20px 20px 0px 20px;
 }
 .search-slider-info-title{
    text-align: center;
    padding: 3px;
    border-bottom: 2px solid var(--color-green);
    font-family: "Josefin Sans", sans-serif;
     font-size: 18px;
     color: var(--color-green);
}
.search-slider-info-card{
    display: flex;
    flex-wrap: wrap;
}
.search-slider-card{
    flex: 1;
    margin: 10px;
    min-width: 340px;
    max-width: 340px;
    height: 350px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 0px 0px 10px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background-color: rgb(253, 243, 230);
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}
.search-slider-img{
    height: 80%;
    width: 100%;
    object-fit: cover;
    
}
.deleteSlide{
    padding: 10px;
    cursor: pointer;
    background-color: rgb(225, 104, 104) !important;
    border: none;
    border-radius: 5px;
    width: max-content;
    color: white !important;
   
}
.deleteSlide:hover{
    background: linear-gradient(rgb(225, 104, 104), #9198e5);
}
@media screen and (max-width: 900px){
    .search-slider-card{
        min-width: 270px;
        max-width: 270px;
    }
}