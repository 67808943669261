.sliderContainer {
  display: flex;
}
.sliderContainerInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 9;
  margin: 20px 20px 0px 20px;
}
.sliderContainerInfoTitle {
  text-align: center;
  padding: 3px;
  border-bottom: 2px solid var(--color-green);
  font-family: "Josefin Sans", sans-serif;
  font-size: 18px;
  color: var(--color-green);
}
.slider-form{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.slider-input {
  display: flex;
  padding: 20px;
  border-bottom: 2px dotted var(--color-green);
  gap: 10px;
}
.slider-input-icon {
  cursor: pointer;
  color: var(--color-green);
}
label {
  font-size: 20px;
  font-family: "Josefin Sans", sans-serif;
  margin-left: 10px;
}
.createSlideBtn{
  padding: 10px;
  cursor: pointer;
  background-color: var(--color-green) !important;
  border: none;
  border-radius: 5px;
  width: max-content;
  color: white !important;
}

.createSlideBtn:hover{
  background: linear-gradient(var(--color-green), #9198e5);
}
.uploadingText{
  margin: 2px 2px 2px 10px;
  color: var(--color-green);

}