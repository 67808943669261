.emailLoginContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  height: 440px;
  margin: 50px;
}

.emailLoginSubtitle {
  font-size: 20px;
  margin-bottom: 20px;
  color: var(--color-green);
}

.form {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input {
  padding: 10px;
  background-color: transparent;
  border: 2px solid #bbb;
  border-radius: 5px;
  outline: none;
  color: black;
  font-size: 15px;
  font-weight: bold;
}
.input:hover {
  border: 2px solid var(--color-green);
}
.button {
  width: 300px;
  padding: 20px;
  cursor: pointer;
  background-color: #53c28b;
  border: none;
  border-radius: 5px;
  color: #eee;
  font-weight: bold;
}

.button:hover {
  background: var(--color-hover);
}
.error {
  color: red;
}

@media screen and (max-width: 900px) {
  .emailLoginContainer {
    height: 580px;
    gap: 0px;
  }
  .button {
    width: 220px;
  }
  .emailLoginSubtitle {
    font-size: 15px;
    margin-bottom: 0;
  }
}
