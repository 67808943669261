.container {
}
.imgContainer {
  width: 100%;
  height: 300px;
  position: relative;
}
.img {
  object-fit: cover;
  filter: grayscale(100%);
  height: 300px;
  width: 100%;
}
.imgText {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: var(--color-green);
  padding: 5px;
  color: white;
}

.textContainer {
  display: flex;
  /* gap: 100px; */
  margin-bottom: 10px;
}
.item {
  /* flex: 1; */
  margin: 20px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.desc {
  font-size: 18px;
  font-weight: 300px;
  text-align: justify;
}
.line-height{
  /* line-height: 1em; */
}
@media screen and (max-width: 900px) {
  .imgText {
    right: 20px;
  }
  .textContainer {
    flex-direction: column;
  }
}
