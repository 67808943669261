.container {
  margin: 40px;
}

.title {
  font-size: 50px;
  margin-bottom: 80px;
  text-align: center;
}

.content {
  display: flex;
  align-items: center;
  gap: 100px;
}

.image {
  flex: 4;

  height: 500px;
  animation: move 3s infinite ease alternate;
}

@keyframes move {
  from {
    transform: translateY(-15px);
  }
  to {
    transform: translateY(0px) scale(1.03);
  }
}

.form {
  flex: 8;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input,
.textArea {
  padding: 10px;
  background-color: transparent;
  border: none;
  outline: none;
  color: black;
  border: 3px solid #bbb;
  font-size: 15px;
  font-weight: bold;
}
.input:hover,
.textArea:hover {
  border: 3px solid var(--color-green);
}
.information {
  display: flex;
  justify-content: center;
}
.information input {
  flex: 1;
}
.information input:last-child {
  margin-left: 12px;
}
.sendBtn{
  padding: 10px;
  cursor: pointer;
  background-color: var(--color-green) !important;
  border: none;
  border-radius: 5px;
  width: max-content;
  color: white !important;
 
}
.sendBtn:hover{
  background: var(--color-hover)
}
@media screen and (max-width: 900px) {
  .image {
    display: none;
  }
  .title {
    font-size: 40px;
  }
  .form {
    justify-content: center;
    align-items: center;
  }
  .information {
    flex-direction: column;
  }
  .information input:last-child {
    margin-left: 0;
    margin-top: 20px;
  }
}
