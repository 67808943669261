.singleNews {
    margin: 30px;
   
  }
  .singleNewsWrapper {
    padding: 20px;
    padding-right: 0;
    gap: 30px;
    display: flex;
    flex-direction: column;

  }
  
  .singleNewsImg {
    width: 100%;
    height: 300px;
    border-radius: 5px;
    object-fit: contain;
  }
  .singleNewsTitle {
    text-align: center;
    margin: 10px;
    font-size: 28px;
    font-family: "Lora", sans-serif;
  }
  .singleNewsEdit {
    float: right;
    font-size: 16px;
  }
  .singleNewsIcon {
    margin-left: 10px;
    cursor: pointer;
  }
  
  
  .singleNewsInfo {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #be9656;
    font-family: "Varela Round", Arial, Helvetica, sans-serif;
  }
  .singleNewsDesc {
    /* text-align: center; */
    color: #666;
    font-size: 18px;
    line-height: 25px;
  }
  
  .singleNewsDesc::first-letter {
    margin-left: 20px;
    font-size: 30px;
    font-weight: 600;
  }
  