.top {
    width: 100%;
    height: 50px;
    background-color: rgb(254, 250, 250);
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    font-family: "Josefin Sans", sans-serif;
    z-index: 999;
  }
  
  .topLeft,
  .topRight {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .topLeft {
  
    font-size: 20px;
    cursor: pointer;
  
  }

  .topCenter {
    flex: 6;
  }
  .logOutBtn{
    padding: 10px;
    cursor: pointer;
    background-color: var(--color-white) !important;
    border: none;
    border-radius: 5px;
    width: max-content;
    color: var(--color-green) !important;
  }
  .topIcon {
    font-size: 20px;
    margin-right: 10px;
    /* color: #444; */
    color: var(--color-green);
    cursor: pointer;
  }
  
  .topList {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .topListItem {
    margin-right: 20px;
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;
  }
  .topListItem:hover {
    color: var(--color-green);
  }
  
  .app__navbar-smallscreen {
    display: none;
  }
  .app__navbar-smallscreen-icons {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .app__navbar-smallscreen_overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgb(254, 250, 250);
    transition: 0.5s ease;
    flex-direction: column;
    z-index: 5;
  }
  .app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color: var(--color-green);
    cursor: pointer;
  
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .app__navbar-smallscreen .overlay__open {
    font-size: 30px;
    color: var(--color-green);
    cursor: pointer;
  
    position: absolute;
    top: 10px;
    right: 20px;
  }
  .app__navbar-smallscreen_links {
    list-style: none;
  }
  
  .app__navbar-smallscreen_links li {
    margin: 1rem;
    cursor: pointer;
    color: var(--color-black);
    font-size: 2rem;
    text-align: center;
  }
  
  .app__navbar-smallscreen_links li:hover {
    color: var(--color-green);
  }
  
  @media screen and (max-width: 900px) {
    .app__navbar {
      padding: 1rem;
    }
  
    .topList {
      display: none;
    }
    .app__navbar-smallscreen {
      display: flex;
    }
    .topRight {
      display: none;
    }
  }
  