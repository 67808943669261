.home {
  display: flex;
}
.line {
  width: 100%;
  border: 5px solid var(--color-green);
  border-style: dotted;
}

.newstitle {
  font-size: 40px;
  text-align: center;
  color: var(--color-green);
  font-family: "Josefin Sans", sans-serif;
}
.homeSlider{
  margin: 10px 100px 10px 100px;
}
@media screen and (max-width: 900px) {
  .home {
    flex-direction: column;
  }
  .display {
    display: none !important;
  }
  .homeSlider{
    margin: 10px 0px 10px 0px;
  }
}
