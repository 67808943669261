.product {
  flex: 1;
  margin: 10px;
  min-width: 500px;
  max-width: 500px;
  height: 500px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: start; */
  background-color: #fff;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}
.product:hover {
  border-left: 3px solid var(--color-green);
  box-shadow: 0 0 10px #ccc;
}

.productImg {
  flex: 7;
  height: 50%;
  width: 100%;
  object-fit: cover;
  /* border-radius: 7px; */
}
.productInfo {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.productCap {
  font-family: "Varela Round", Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: var(--color-green);
  font-weight: bold;
  margin-top: 15px;
  cursor: pointer;
}
.productTitle {
  font-family: "Josefin Sans", Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin-top: 15px;
  cursor: pointer;
  text-align: center;
}

.productDesc {
  flex: 3;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #444444;
  margin-top: 15px;
}

@media screen and (max-width: 900px){
    .product{
        min-width: 300px;
        max-width:300px;
    }
}