.singleProduct {
  margin: 30px;
}
.singleProductWrapper {
  padding: 20px;

  gap: 30px;
  display: flex;
}

.singleProductImg {
  flex: 1;
  width: 600px;
  /* height: 380px; */
  border-radius: 5px;
  object-fit: cover;
  margin-bottom: 20px;
}

.singleProductInfo {
  flex: 1;
  gap: 10px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #be9656;
  font-family: "Varela Round", Arial, Helvetica, sans-serif;
}
.singleProductTitle {
  text-align: center;
  margin: 10px;
  font-size: 28px;
  font-family: "Varela Round", Arial, Helvetica, sans-serif;
}
.singleProductDesc {
  color: #666;
  font-size: 15px;
  line-height: 20px;
}
.singleProductCap {
  font-family: "Varela Round", Arial, Helvetica, sans-serif;
  text-align: center;
  font-size: 15px;
  color: var(--color-green);
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 10px;
  cursor: pointer;
}
.singleProductDesc::first-letter {
  margin-left: 20px;
  font-size: 30px;
  font-weight: 600;
}

@media screen and (max-width: 900px) {
  .singleProductWrapper {
    flex-direction: column;
  }

  .singleProductImg {
    width: 100%;
  }
 
}
